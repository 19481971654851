body, html {
  height: 100%;
  margin: 0;
}

.bg {
  background-image: url("./image/1.jpg");
  height: 100%; 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}